@.imageOptionsModuleHandler = ->
    $(document).ready(->
        moduleEventHandler()
    )
    moduleEventHandler = ->
        $('.modal-image-options__button--save').on('click', (e) ->
            e.preventDefault()
            modal = $(this).parents('#modalImageOptions')
            $.ajax({
                type: 'POST',
                url: '/myspacestor/change-favourite/',
                data: {
                    'type': modal.attr('data-object-type'),
                    'object_id': modal.attr('data-object-id'),
                    'source': modal.attr('data-source') || 'product-gallery-image',
                    'favourite': !modal.data('favourite'),
                    'image_field': modal.attr('data-image-field') || ''
                },
                success: (data) =>
                    if data.login_required
                        $('#modalImageOptions').removeClass('active')
                        $('#modalSignIn').addClass('active')
                    else
                        added = data.is_liked
                        modal.data('favourite', !modal.data('favourite'))
                        $('#modalImageOptions').removeClass('active')

                error: (data) =>
                    console.log('failure ' + data)

                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
        )
